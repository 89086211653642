/* External dependencies */
import React from 'react';
import get from 'lodash/get';

/* Internal dependencies */
import MediaObject, { Props as MediaObjectProps } from '../mediaObject/MediaObject';
import { formatNumber } from 'src/common/helpers/numbers';
import { Link } from 'react-router-dom';

type OwnProps = {
  community: any;
  right?: React.ReactNode;
  enableLink?: boolean;
} & Partial<MediaObjectProps>;

type Props = OwnProps;

const Community: React.FunctionComponent<Props> = ({ community, right, enableLink = false, textSize, ...rest }) => {
  const communityId = get(community, 'id', '');
  const members = get(community, 'members.items', []);
  const membersTotal = get(community, 'members.total', members.length);
  const attendees = get(community, 'attendees.items', []);
  const attendeesTotal = get(community, 'attendees.total', attendees.length);

  return (
    <MediaObject
      text={Boolean(enableLink) ? (
        <Link
          to={`/communities/${communityId.replace('community:', '')}`}
        >
          <h6 className="bm-MediaObject_text text-white d-flex flex-wrap m-0 p-0" style={{ fontSize: textSize }}>{get(community, 'name')}</h6>
        </Link>
      ) : get(community, 'name')}
      textSize={textSize}
      subtext={`${formatNumber(membersTotal)} ${membersTotal === 1 ? 'member' : 'members'}${Boolean(attendeesTotal) ? `, ${formatNumber(attendeesTotal)} ${attendeesTotal === 1 ? 'attendee' : 'attendees'}` : ''}`}
      imageType="rounded"
      image={get(community, 'images[0].url')}
      right={right}
      {...rest}
    />
  );
};

export default Community;
