/* External dependencies */
import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { Dropdown, Image } from 'react-bootstrap';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import shuffle from 'lodash/shuffle';
import Helmet from 'react-helmet';

/* Internal dependencies */
import Spinner from 'src/spinner/Spinner';
import Footer from 'src/footer/Footer';
import Navbar from 'src/navbar/Navbar';
import Button from 'src/button/Button';
import { listEventsForLocation, listLocations } from 'src/api/locations';
import Icon, { Icons } from 'src/icon/Icon';
import MediaObject from 'src/mediaObject/MediaObject';
import './Events.scss';
import { listBeatmatchEvents } from 'src/api/events';
import SubscribeButton from 'src/subscribe/SubscribeButton';
import ScrollToTop from 'src/scrollToTop/ScrollToTop';
import { User } from 'src/types/User';
import { slugify } from 'src/store/helpers/users';
import JoinEventButton from './JoinEventButton';
import Colors from 'src/colors';

type Props = {};

type State = {
  locations: any[];
  events: any[];
  collections: any[];
  beatmatchEvents: any[];
  loading: boolean;
  eventsLoading: boolean;
  activeLocationIndex: number;
};

export const providerToProviderCode: { [provider: string]: string } = {
  beatmatch: 'b',
  ticketmaster: 't',
  eventbrite: 'e',
  axs: 'a',
  sofarsounds: 's',
  dice: 'd',
  posh: 'p',
};
export const providerCodeToProvider: { [code: string]: string }  = Object.keys(providerToProviderCode).reduce((acc, curr) => ({
  ...acc,
  [providerToProviderCode[curr]]: curr,
}), {});

class Events extends React.Component<Props, State> {
  state = { locations: [], events: [], collections: [], beatmatchEvents: [], activeLocationIndex: 0, loading: true, eventsLoading: false };

  async componentDidMount() {
    try {
      const { activeLocationIndex } = this.state;
      let locations = await this.loadLocations();

      locations = locations.sort(({ name: v1 }: any, { name: v2 }: any) => v1.localeCompare(v2));
      const activeLocation = locations[activeLocationIndex];

      if (activeLocation) {
        const { items: collections = [] } = await listEventsForLocation(activeLocation.id, { limit: 30 });
        this.setState({ collections });
      }
    } catch (e) {
      console.log('componentDidMount error', e);
    } finally {
      this.setState({ loading: false });
    }
  }

  loadLocations = async () => {
    try {
      const { items: locations = [] } = await listLocations();
      this.setState({ locations });
      return locations;
    } catch (e) {
      console.log(e);
    }
  };

  loadEvents = async (locationId: string) => {
    const { items: collections = [] } = await listEventsForLocation(locationId, { limit: 30 });
    this.setState({ collections });
  };

  createHandleLocationSelect = (activeLocationIndex: number) => async () => {
    this.setState({ eventsLoading: true });
    try {
      const { locations = [] } = this.state;
      const newActiveLocation = locations[activeLocationIndex] as any;

      this.setState({ activeLocationIndex });

      if (newActiveLocation) {
        await this.loadEvents(newActiveLocation.id);
      }

    } catch (e) {
      console.log('loading events error');
    } finally {
      this.setState({ eventsLoading: false });
    }
  };

  renderFeaturedCollection = ({ id, items = [], title }: any) => {
    const { locations = [], activeLocationIndex, eventsLoading } = this.state;
    const activeLocation = locations[activeLocationIndex] as any;
    const events = uniqBy(items, 'id').slice(0, 5);
    const titleParts = title.split(' ');
    const oneWeekAgo = moment(Date.now()).subtract(1, 'week').toDate().getTime();

    return (
      <section key={id} className="container-fluid pb-2 mb-5 text-white" style={{ backgroundColor: '#060710', minHeight: '45vh' }}>
        <div className="container mb-4 pb-3">
          <div className="d-flex justify-content-between flex-wrap mb-4">
            <h2 className="bm-Events_sectionHeader text-lowercase" style={{ flex: 1 }}>
              {titleParts.map((str: string, i: number) => {
                if (i === 0) {
                  return <span>{str} </span>;
                }
                return <span className="bm-Text--strokeText">{str}</span>;
              })}
            </h2>
            {id !== 'beatmatchPresents' && (
              <div className="d-flex justify-content-between align-items-center">
                <Button
                  className="btn btn-secondary d-flex align-items-center"
                  variant="secondary"
                  style={{ borderRadius: 50, paddingTop: 10, paddingBottom: 10, maxHeight: 75 }}
                  dropdownItems={locations.map(({ id, name, emoji }, i) =>[
                    <Dropdown.Item key={id} onClick={this.createHandleLocationSelect(i)}>
                      {emoji} {name}
                    </Dropdown.Item>,
                  ])}
                >
                  {Boolean(activeLocation) && <h5 className="m-0 p-0 mr-1 text-dark">{activeLocation.emoji} {activeLocation.name}</h5>}<Icon name={Icons.chevronDown} className="bm-Icon--black" size={20} />
                </Button>
                {Boolean(false) && (
                  <Link
                    to="/events/new"
                    className="btn btn-primary ml-3"
                    style={{ borderRadius: 50, paddingTop: 10, paddingBottom: 10, maxHeight: 75 }}
                  >
                    <h5 className="m-0 p-0">Create event</h5>
                  </Link>
                )}
                {/* TODO: Add search bar */}
              </div>
            )}
          </div>
          {Boolean(eventsLoading) && id !== 'beatmatchPresents' && (
            <div className="d-flex flex-column justify-content-center" style={{ minHeight: '50vh', overflowX: 'hidden' }}>
              <Spinner />
            </div>
          )}
          {(!Boolean(eventsLoading) || (id === 'beatmatchPresents')) && (
            <div className="bm-Events__featuredEvents d-flex" style={{ paddingLeft: 190, paddingRight: 190, marginLeft: -190, marginRight: -205 }}>
              {events.sort((e1, e2) => new Date((e1 as any).date).getTime() - new Date((e2 as any).date).getTime()).map(({ id, name, venue, date, timezone = get(activeLocation, 'timezone', 'America/Los_Angeles'), images = [], provider, providerId, users, createdAt }: any) => {
                const momentDate = moment(date);
                const today = moment();
                const tomorrow = moment().add(1, 'day');
                const isToday = momentDate.isSame(today, 'day');
                const isTomorrow = momentDate.isSame(tomorrow, 'day');
                const userItems = get(users, 'items', []);
                const slug = slugify(name);
                const venueContent = (
                  <h4 className="bm-Events__featuredEvents__subtext">
                    {get(venue, 'name')}
                  </h4>
                );

                return (
                  <div key={id} className="mr-4" style={{ maxWidth: 425 }}>
                    <Link to={`/events/${providerToProviderCode[provider]}/${providerId}/${slug}`}>
                      <div style={{ position: 'relative' }}>
                        <Image
                          className="bm-Events__featuredEvent_image mb-3"
                          src={get(images, '[0].url')}
                          style={{ aspectRatio: '1', borderRadius: 20, objectFit: 'cover' }}
                        />
                        {createdAt && new Date(createdAt).getTime() >= oneWeekAgo && (
                          <div style={{ position: 'absolute', top: 4, left: 10 }}>
                            <div
                              className="mt-3 ml-2 pl-3 pr-3 pt-1 pb-1 bg-primary justify-content-center align-items-center rounded"
                            >
                              <h5 className="text-white text-bold" style={{ margin: 0, marginBottom: 2 }}>
                                New
                              </h5>
                            </div>
                          </div>
                        )}
                      </div>
                    </Link>
                    <div>
                      <div className="d-flex align-items-center">
                        {(isToday || isTomorrow) && (
                          <>
                            <h5 className="text-bold text-primary mr-2">
                              {isToday ? 'TODAY' : isTomorrow ? 'TOMORROW' : momentDate.tz(timezone).format('ddd').toUpperCase()}
                            </h5>
                            <h5 className="text-bold">
                            {momentDate.tz(timezone).format('MMM').toUpperCase()} {momentDate.tz(timezone).format('D').toUpperCase()}
                            </h5>
                          </>
                        )}
                        {!isToday && !isTomorrow && (
                          <>
                            <h5 className="text-bold text-primary mr-2">
                              {momentDate.tz(timezone).format('ddd').toUpperCase()}
                            </h5>
                            <h5 className="text-bold">
                              {momentDate.tz(timezone).format('MMM').toUpperCase()} {momentDate.tz(timezone).format('D').toUpperCase()} -{' '}
                              {momentDate.tz(timezone).format('h:mm A')}
                            </h5> 
                          </>
                        )}
                        {Boolean(userItems.length >= 10) && (
                          <div className="d-flex justify-content-center align-items-center ml-3" style={{ marginBottom: '0.4rem' }}>
                            <i className="fa fa-fire text-white mr-1" style={{ fontSize: 12 }} />
                            <h6 className="text-white text-bold" style={{ margin: 0, fontSize: 14 }}>
                              Trending
                            </h6>
                          </div>
                        )}
                      </div>
                      <h4 className="text-bold mb-2">{name}</h4>
                      {Boolean(venue) && (
                        <>
                          {Boolean(venue.id) ? (
                            <Link
                              to={`/venues/${get(venue, 'id', '').replace('venue:', '')}/${slugify(get(venue, 'name', ''))}`}
                              className="bm-Event__horizontalItemSubtext"
                            >
                              {venueContent}
                            </Link>
                          ) : venueContent}
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </section>
    );
  };

  renderCalendarCollection = ({ id, items = [], title }: any) => {
    const { collections = [], locations = [], activeLocationIndex, eventsLoading } = this.state;
    const beatmatchPresentsCollection = collections.find(({ id }) => id === 'beatmatchPresents');
    const activeLocation = locations[activeLocationIndex] as any;
    const dayGroups = groupBy(items, ({ date }) => {
      return moment(date).startOf('day').format();
    });
    const days = Object.keys(dayGroups);
    const titleParts = title.split(' ');
    const oneWeekAgo = moment(Date.now()).subtract(1, 'week').toDate().getTime();

    return (
      <section key={id} className="container">
        <div className="mb-5">
          <div className="d-flex justify-content-between flex-wrap" style={{ width: '100%' }}>
            <h2 className="bm-Events_sectionHeader text-dark text-lowercase" style={{ flex: 1 }}>
              {titleParts.map((str: string, i: number) => {
                if (i === 0) {
                  return <span>{str} </span>;
                }
                return <span className="bm-Text--strokeText bm-Text--strokeText-dark">{str}</span>;
              })}
            </h2>
            {Boolean(beatmatchPresentsCollection) && (
              <div className="d-flex justify-content-between align-items-center">
                <Button
                  className="btn btn-secondary d-flex align-items-center"
                  variant="secondary"
                  style={{ borderRadius: 50, paddingTop: 10, paddingBottom: 10, maxHeight: 75 }}
                  dropdownItems={locations.map(({ id, name, emoji }, i) =>[
                    <Dropdown.Item key={id} onClick={this.createHandleLocationSelect(i)}>
                      {emoji} {name}
                    </Dropdown.Item>,
                  ])}
                >
                  {Boolean(activeLocation) && <h3 className="m-0 p-0 mr-1">{activeLocation.emoji} {activeLocation.name}</h3>}<Icon name={Icons.chevronDown} size={26} />
                </Button>
                {/* TODO: Add search bar */}
              </div>
            )}
          </div>
        </div>
        {Boolean(eventsLoading) && (
          <div className="d-flex flex-column justify-content-center" style={{ minHeight: '50vh', overflowX: 'hidden' }}>
            <Spinner />
          </div>
        )}
        {!Boolean(eventsLoading) && (
          <>
            {days.sort().map((day, i) => {
              const momentDate = moment(day);
              const dayEvents = dayGroups[day];
              const today = moment();
              const tomorrow = moment().add(1, 'day');
              const isToday = momentDate.isSame(today, 'day');
              const isTomorrow = momentDate.isSame(tomorrow, 'day');

              return (
                <section className="pb-5 mb-5" style={{ borderBottom: i < days.length - 1 ? '2px solid rgba(35, 40, 82, 0.1)' : undefined }}>
                  <div className="d-flex mb-2">
                    {(isToday || isTomorrow) && (
                      <div className="d-flex">
                        <h2 className="text-bold text-primary mr-2">
                          {isToday ? 'TODAY' : isTomorrow ? 'TOMORROW' : momentDate.format('ddd').toUpperCase()}
                        </h2>
                        <h2 className="text-bold">
                        {momentDate.format('MMM').toUpperCase()} {momentDate.format('D').toUpperCase()}
                        </h2>
                      </div>
                    )}
                    {!isToday && !isTomorrow && (
                      <div className="d-flex">
                        <h2 className="text-bold text-primary mr-2">
                          {momentDate.format('ddd').toUpperCase()}
                        </h2>
                        <h2 className="text-bold">
                          {momentDate.format('MMM').toUpperCase()} {momentDate.format('D').toUpperCase()}
                        </h2>
                      </div>      
                    )}
                  </div>
                  {dayEvents.map((event: any) => {
                    const {
                      name, venue, date,
                      timezone = get(activeLocation, 'timezone', 'America/Los_Angeles'),
                      images = [], provider, providerId,
                      externalUrl, createdAt, users,
                    } = event;
                    const momentDate = moment(date);
                    const imageUrl = get(images, '[0].url', get(event, 'artists.items[0].images[0].url'));
                    const userItems = get(users, 'items', []);
                    const slug = slugify(name);
                    const venueContent = (
                      <h6 className="bm-MediaObject_text bm-MediaObject__subtext d-flex flex-wrap">
                        {get(venue, 'name')}
                      </h6>
                    );

                    return (
                      <div className="bm-Event__eventsFeed d-flex align-items-center">
                        <MediaObject
                          image={(
                            <Link className="mr-3" to={`/events/${providerToProviderCode[provider]}/${providerId}/${slug}`} style={{ width: '100%' }}>
                              <div style={{ width: '100%', position: 'relative' }}>
                                {Boolean(imageUrl) && (
                                  <Image
                                    src={imageUrl}
                                    width={200}
                                    style={{ aspectRatio: '1', borderRadius: 20, objectFit: 'cover' }}
                                  />
                                )}
                                {!Boolean(imageUrl) && (
                                  <div
                                    className="d-flex justify-content-center align-items-center mr-3"
                                    style={{ aspectRatio: '1', minWidth: 200, width: '100%', borderRadius: 20, backgroundColor: '#271845', objectFit: 'cover' }}
                                  >
                                    <Icon
                                      name={Icons.ticket}
                                      className="bm-Icon--lightGrey"
                                      size={75}
                                    />
                                  </div>
                                )}
                                {createdAt && new Date(createdAt).getTime() >= oneWeekAgo && (
                                  <div style={{ position: 'absolute', top: 2, left: 4 }}>
                                    <div
                                      className="mt-3 ml-2 pl-2 pr-2 pt-1 pb-1 bg-primary justify-content-center align-items-center rounded"
                                    >
                                      <h6 className="text-white text-bold" style={{ margin: 0 }}>
                                        New
                                      </h6>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </Link>
                          )}
                          imageSize={200}
                          text={(
                            <div style={{ maxWidth: 400 }}>
                              <div className="d-flex">
                                {/* <h6 className="text-bold text-primary mr-2">
                                  {momentDate.format('ddd').toUpperCase()}
                                </h6> */}
                                <h5 className="text-bold mr-3">
                                  {/* {momentDate.format('MMM').toUpperCase()} {momentDate.format('D').toUpperCase()} -{' '} */}
                                  {momentDate.tz(timezone).format('h:mm A')}
                                </h5>
                                {Boolean(userItems.length >= 10) && (
                                  <div
                                    className="pl-2 pr-2 pt-1 pb-1 bg-primary rounded"
                                    style={{
                                      maxWidth: 125,
                                    }}
                                  >
                                    <div className="d-flex justify-content-center align-items-center" style={{ paddingTop: 3 }}>
                                      <i className="fa fa-fire text-white mr-1" style={{ fontSize: 12, paddingTop: 2 }} />
                                      <h6 className="text-white text-bold" style={{ margin: 0, fontSize: 14 }}>
                                        Trending
                                      </h6>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <Link className="text-dark" to={`/events/${providerToProviderCode[provider]}/${providerId}/${slug}`}>
                                <h6 className="bm-MediaObject_text">{name}</h6>
                              </Link>
                            </div>
                          )}
                          subtext={(
                            <div>
                              {Boolean(venue) && (
                                <>
                                  {Boolean(venue.id) ? (
                                    <Link
                                      to={`/venues/${get(venue, 'id', '').replace('venue:', '')}/${slugify(get(venue, 'name', ''))}`}
                                      className="bm-Event__horizontalItemSubtext"
                                    >
                                      {venueContent}
                                    </Link>
                                  ) : venueContent}
                                </>
                              )}
                            </div>
                          )}
                          right={(
                            <div className="bm-Event__buttonGroup d-flex">
                              {/* <Button href={externalUrl} target="_blank" className="bm-Events__getTicketsBtn" variant="secondary">
                                <h5 className="pt-1">Get tickets</h5>
                              </Button> */}
                              {/* <SubscribeButton
                                className="bm-Events__findYourMatchBtn"
                                variant="primary"
                                header={(
                                  <div className="d-flex flex-column align-items-center">
                                    {Boolean(userItems && userItems.length) && (
                                      <div className="d-flex align-items-center mb-2">
                                        {shuffle(userItems).slice(0, 3).map(({ images }: User, i: number) => (
                                          <Image
                                            key={i}
                                            src={get(images, '[0].url', 'https://i.stack.imgur.com/l60Hf.png')}
                                            style={{
                                              zIndex: userItems.slice(0,3).length - i,
                                              height: 100,
                                              width: 100,
                                              borderRadius: 50,
                                              marginLeft: i === 0 ? 0 : -20,
                                              borderWidth: 4,
                                              borderStyle: 'solid',
                                              borderColor: '#FFF',
                                              objectFit: 'cover',
                                            }}
                                          />
                                        ))}
                                      </div>
                                    )}
                                    <h1 className="text-center mb-3">Meet everyone going to {name}!</h1>
                                  </div>
                                )}
                              >
                                <h5 className="pt-1">Join</h5>
                              </SubscribeButton> */}
                              <JoinEventButton
                                event={event}
                                showIcon={Boolean(event && event.added)}
                                render={({ onClick, added }) => (
                                  <Button
                                    disabled={!event}
                                    className="btn btn-block d-flex justify-content-center align-items-center pl-3 pr-3"
                                    variant="primary"
                                    onClick={onClick}
                                    style={{
                                      borderRadius: 100,
                                      borderColor: Boolean(added) ? Colors.primary : Colors.inactiveTab,
                                      borderWidth: Boolean(added) ? 2.5 : 0,
                                      backgroundColor: Boolean(added) ? Colors.transparent : Colors.primary,
                                      minWidth: 100,
                                    }}
                                  >
                                    <div className="d-flex align-items-center">
                                      {Boolean(added) && (
                                        <div className="mr-2 pb-1">
                                          {Boolean(added) ? (
                                            <Icon name={Icons.check} className="bm-Icon--primary" solid size={22} />
                                          ) : (
                                            <Icon name={Icons.plus} className="bm-Icon--white" size={22} />
                                          )}
                                        </div>
                                      )}
                                      <h5 className="pt-1 text-bold" style={{ color: Boolean(added) ? Colors.primary : Colors.white }}>{Boolean(added) ? 'Joined' : 'Join'}</h5>
                                    </div>
                                  </Button>
                                )}
                              />
                            </div>
                          )}
                        />
                      </div>
                    );
                  })}
                </section>
              );
            })}
          </>
        )}
      </section>
    );
  };

  renderCollection = (collection: any) => {
    const { type, items = [] } = collection;
    console.log('collection type', type);
    console.log('collection items', items);
    if (type === 'featured') {
      return this.renderFeaturedCollection(collection);
    } else if (type === 'calendar') {
      return this.renderCalendarCollection(collection);
    }

    return;
  };

  render() {
    const { locations = [], events = [], collections = [], activeLocationIndex, loading, eventsLoading } = this.state;
    const dayGroups = groupBy(events, ({ date }) => {
      return moment(date).startOf('day').format();
    });
    const days = Object.keys(dayGroups);
    const title = 'Beatmatch | Concerts, Parties, Music Festivals Near You';

    return (
      <div className="bg-white" style={{ minHeight: '100vh', overflowX: 'hidden' }}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{title}</title>
          <meta property="og:determiner" content="a" />
          <meta
            property="og:description"
            content="Meet people IRL at concerts, parties, and music festivals. Discover upcoming events on Beatmatch."
          />
          <meta property="og:url" content={`https://${process.env.REACT_APP_DOMAIN_NAME}`} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={title} />
          <meta
            property="og:image"
            content={`http://${process.env.REACT_APP_DOMAIN_NAME}${process.env.REACT_APP_PUBLIC_URL}/welcome-to-beatmatch.png`}
          />
          <meta property="og:site_name" content="Beatmatch" />
          <meta
            property="twitter:description"
            content="Meet people IRL at concerts, parties, and music festivals. Explore events online and offline on Beatmatch."
          />
          <meta name="twitter:site" content="@joinbeatmatch" />
          <meta name="twitter:creator" content="@joinbeatmatch" />
          <meta property="twitter:url" content={`https://${process.env.REACT_APP_DOMAIN_NAME}`} />
          <meta
            property="twitter:image"
            content={`https://${process.env.REACT_APP_DOMAIN_NAME}/welcome-to-beatmatch.png`}
          />
          <meta property="twitter:title" content={title} />
          <meta property="twitter:card" content="summary" />
          <link rel="canonical" href={`https://${process.env.REACT_APP_DOMAIN_NAME}`} />
          <script type="application/ld+json">
            {`{
                "@context": "https://schema.org",
                "@type": "Organization",
                "url": "${`https://${process.env.REACT_APP_DOMAIN_NAME}`}",
                "logo": "${`https://${process.env.REACT_APP_DOMAIN_NAME}/logo512.png`}"
              }`}
          </script>
          <script type="application/ld+json">
            {`{
                "@context": "https://schema.org",
                "@type": "SoftwareApplication",
                "name": "Beatmatch",
                "operatingSystem": "IOS",
                "applicationCategory": "SocialNetworkingApplication",
                "applicationSubCategory": "MusicApplication",
                "downloadUrl": "https://beatmatch.app.link"
              }`}
          </script>
          <script id="mcjs">{`!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/1328aa7a8f414acc4aaad2634/467d56da5fc744cb331e4ee25.js");`}</script>
        </Helmet>
        <ScrollToTop />
        <Navbar alwaysFillNavbar={false} />
        {Boolean(loading) && (
          <div className="d-flex flex-column justify-content-center" style={{ marginTop: -75, backgroundColor: '#060710', minHeight: '100vh', overflowX: 'hidden' }}>
            <Spinner />
          </div>
        )}
        {!Boolean(loading) && (
          <>
            <section className="bm-Events__section1 container-fluid" style={{ backgroundColor: '#060710' }}>
              {/* <div className="container">
                <h1 className="text-white pb-4" style={{ margin: 0 }}>Upcoming Events</h1>
              </div> */}
            </section>
            {collections.map(this.renderCollection)}
          </>
        )}
        <Footer />
      </div>
    );
  }
}

export default Events;